$(document).ready(function() {

    AOS.init({
        duration: 1000
    });

    $(".section-deals__list.owl-carousel").owlCarousel({
        nav: true,
        autoplay: true,
        loop: true,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                margin: 8,
                items: 2,
                nav: false,
            },
            480: {
                margin: 12
            },
            1024: {
                margin: 17
            }
        }
    });

    $(".section-feedback__wrapper.owl-carousel").owlCarousel({
        nav: true,
        autoplay: true,
        loop: true,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                margin: 0,
                items: 1,
                nav: false,
            },
            480: {
                margin: 11
            },
            1024: {
                margin: 16
            }
        }
    });

    $('.faq-page .section-list__item-title').on('click', function (e) {
        e.preventDefault();

        $(this).siblings('.section-list__item-text').slideToggle();
    });

    $('.section-slider__content-heading-item').on('click', function (e) {
        e.preventDefault();

        set_slider($(this).index());
    });

    $('.section-slider__nav-prev').on('click', function (e) {
        e.preventDefault();

        var index = $('.section-slider__img-item_active').index();

        index = index === 0 ? $('.section-slider__img-list .section-slider__img-item').length - 1 : index - 1;

        set_slider(index);
    });

    $('.section-slider__nav-next').on('click', function (e) {
        e.preventDefault();

        var index = $('.section-slider__img-item_active').index();

        index = index === ($('.section-slider__img-list .section-slider__img-item').length - 1) ? 0 : index + 1;

        set_slider(index);
    });

    function sliderHeight() {
        var maxHeight = 0;

        $('.section-slider__content-text-item').each(function () {
            if($(this).outerHeight() > maxHeight) {
                maxHeight = $(this).outerHeight();
            }
        });

        $('.section-slider__content-text').height(maxHeight);
    }

    sliderHeight();
    // $(window).bind("resize", sliderHeight);

    function set_slider(index) {
        $('.section-slider__img-list .section-slider__img-item')
            .removeClass('section-slider__img-item_active')
            .eq(index)
            .addClass('section-slider__img-item_active');

        $('.section-slider__content-heading-item')
            .removeClass('section-slider__content-heading-item_active')
            .eq(index)
            .addClass('section-slider__content-heading-item_active');

        $('.section-slider__content-text-item')
            .removeClass('section-slider__content-text-item_active')
            .eq(index)
            .addClass('section-slider__content-text-item_active');
    }


    function sliderInterval() {
        return setInterval(function () {
            $('.section-slider__nav-next').trigger('click');
        }, 5000);
    }
    var slider_interval = sliderInterval();

    $('.section-slider__nav-prev, .section-slider__nav-next, .section-slider__content-heading-item, .section-slider__content-text-item')
        .on('mouseenter', function () {
            clearInterval(slider_interval);
        })
        .on('mouseleave', function () {
            slider_interval = sliderInterval();
        });

    $('input, textarea').on('blur', function (e) {
        e.preventDefault();

        if($(this).val()) {
            $(this).addClass('filled');
        } else {
            $(this).removeClass('filled');
        }
    });

    $('.modal__closer, .modal__overlay').on('click', function () {
        $(this).closest('.modal').removeClass('modal_active');
    });

    $('[data-toggle="modal"]').on('click', function (e) {
        e.preventDefault();

        $($(this).data('target')).addClass('modal_active');
    });

    $('.form select').selectize();

    $('#menuButton').on('click', function (e) {
        e.preventDefault();

        $(this).toggleClass('is-active');
    });

    $('[data-toggle="dropdown"]').on('click', function (e) {
        e.preventDefault();

        $($(this).data('target')).toggleClass('opened');
    });

    $(document).on('click', '*', function (e) {
        e.stopPropagation();

        if(!$(this).closest('.dropdown').length && $(this).data('toggle') !== 'dropdown') {
            $('.dropdown').removeClass('opened');
        }
    });

    $('input[name=deposit-type]').on('change', function () {
        var index = $(this).closest('.tabs__nav-item').index();

        $('.tabs__nav-item').removeClass('active').eq(index).addClass('active');
        $('.tabs__content-item').removeClass('active').eq(index).addClass('active');
    });

    $('.head__opener').on('click', function () {
        $(this).toggleClass('opened');
        $('.head__nav-wrapper').slideToggle();
    });

    // CHART 1
    var chart_values = $('#chart-values').get(0);

    if(chart_values) {
        chart_values = chart_values.getContext('2d');

        var chart_values_gradient = chart_values.createLinearGradient(0, 0, 0, 450);

        chart_values_gradient.addColorStop(0, 'rgba(28,102,190,0.64)');
        chart_values_gradient.addColorStop(0.3, 'rgba(3,187,224,0.68)');
        chart_values_gradient.addColorStop(1, 'rgba(7,212,255,0.64)');

        new Chart(chart_values, {
            type: 'line',
            data: {
                labels: ['5d', '10d', '15d', '20d', '25d', '30d', '35d', '40d'],
                datasets: [{
                    label: false,
                    data: [0, 37, 25, 45, 85, 30, 50, 15],
                    backgroundColor: chart_values_gradient,
                    borderColor: '#1c66be',
                    borderWidth: 1
                }]
            },
            options: {
                legend: {
                    display: false,
                },
                elements: {
                    point:{
                        radius: 0
                    }
                },
                scales: {
                    xAxes: [{
                        offset: true,
                        gridLines: {
                            display:false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            stepSize: 20,
                        },
                        offset: true,
                        gridLines: {
                            display:false
                        }
                    }]
                }
            }
        });
    }

    // CHART 2
    var chart_profits = $('#chart-profits').get(0);

    if(chart_profits) {
        chart_profits = chart_profits.getContext('2d');

        var chart_profits_gradient = chart_profits.createLinearGradient(0, 0, 0, 450);

        chart_profits_gradient.addColorStop(0, 'rgba(28,102,190,0.64)');
        chart_profits_gradient.addColorStop(0.3, 'rgba(3,187,224,0.68)');
        chart_profits_gradient.addColorStop(1, 'rgba(7,212,255,0.64)');

        new Chart(chart_profits, {
            type: 'line',
            data: {
                labels: ['5d', '10d', '15d', '20d', '25d', '30d', '35d', '40d'],
                datasets: [{
                    label: false,
                    data: [10, 12, 11, 23, 13, 18, 12, 15],
                    backgroundColor: chart_profits_gradient,
                    borderColor: '#1c66be',
                    borderWidth: 1
                }]
            },
            options: {
                legend: {
                    display: false,
                },
                elements: {
                    point:{
                        radius: 0
                    }
                },
                scales: {
                    xAxes: [{
                        offset: true,
                        gridLines: {
                            display:false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            stepSize: 5,
                            callback: function(value, index, values) {
                                return value + '%';
                            }
                        },
                        offset: true,
                        gridLines: {
                            display:false
                        }
                    }]
                }
            }
        });
    }

    // CHART 3
    var chart_portfolio = $('#chart-portfolio').get(0);

    if(chart_portfolio) {
        chart_portfolio = chart_portfolio.getContext('2d');

        new Chart(chart_portfolio, {
            type: 'pie',
            data: {
                labels: ['First Type', 'Second Type', 'Third Type', 'Fourth Type', 'Fifth Type'],
                datasets: [{
                    label: false,
                    data: [45, 15, 15, 15, 10],
                    backgroundColor: [
                        '#3e99f5',
                        'rgba(62,153,245,0.72)',
                        'rgba(60,161,255,0.23)',
                        'rgba(62,153,245,0.35)',
                        '#28c6f5',
                    ],
                    // borderColor: '#1c66be',
                    borderWidth: 0
                }]
            },
            options: {
                legend: {
                    // display: false,
                    position: 'right'
                },
                elements: {
                    point:{
                        radius: 0
                    }
                },
            }
        });
    }

    $('body').on('click', '.alert-close, .alert-overlay', function () {
        $(this).closest('.alert').removeClass('active');
    });

    function show_alert(el, time = undefined) {
        $(el).append('<div class="alert-overlay"></div>');
        $(el).addClass('active').find('.alert-content').append('<div class="alert-close"></div>');

        if(time !== undefined && Number.isInteger(time)) {
            setTimeout(function () {
                $(el).removeClass('active');
            }, time);
        }
    }

    show_alert('#alert-reset-password-success');

});